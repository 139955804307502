<template>
  <v-container class="pb-5 mb-5">
    <div>
      <v-row justify="center">
        <v-col cols="12">
          <v-card
            v-if="isLoading"
            class="pa-5 rounded-lg"
            :style="{
              backgroundColor: $vuetify.theme.dark ? 'var(--dark-bg-lhp)' : 'var(--light-bg-lhp)',
            }"
            style="z-index: 1; backdrop-filter: blur(5px); -webkit-backdrop-filter: blur(5px)"
          >
            <v-card-title class="d-flex justify-center text-h6 font-weight-bold"> Mohon Tunggu </v-card-title>
            <v-card-text class="text-center text-subtitle-2 font-weight-bold"> Sedang memuat data... </v-card-text>
          </v-card>
          <v-card
            v-else-if="detail"
            class="pa-5 rounded-lg"
            :style="{
              backgroundColor: $vuetify.theme.dark ? 'var(--dark-bg-lhp)' : 'var(--light-bg-lhp)',
            }"
          >
            <div>
              <v-img :src="detail.image" contain max-height="300" class="rounded-lg"></v-img>
            </div>
            <!-- Tanggal -->
            <div class="d-flex justify-end mt-2"><span class="text-caption font-weight-bold">Rabu, 31 Mei 2024 PUKUL 15:22</span></div>

            <!-- Status -->
            <v-sheet class="px-5 py-3 rounded-xl my-3 d-flex align-center" style="background-color: rgba(229, 32, 32, 0.1); color: rgb(229, 32, 32)">
              <v-icon color="red" class="mr-2 text-subtitle-1">mdi-emoticon-sad-outline</v-icon>
              <span class="text-caption font-weight-bold">Belum Tertagih</span>
            </v-sheet>

            <!-- Informasi Penagihan -->
            <v-card flat class="pa-5 rounded-lg">
              <v-row class="py-1">
                <v-col cols="6" class="text-caption text--secondary">KC</v-col>
                <v-col cols="6" class="text-caption text--secondary text-right"> TIGARAKSA </v-col>
              </v-row>
              <v-divider />

              <v-row class="py-1">
                <v-col cols="6" class="text-caption text--secondary">ID Anggota</v-col>
                <v-col cols="6" class="text-caption text--secondary text-right"> 102010132000419 </v-col>
              </v-row>
              <v-divider />

              <v-row class="py-1">
                <v-col cols="6" class="text-caption text--secondary">Nama Anggota</v-col>
                <v-col cols="6" class="text-caption text--secondary text-right"> Dewi </v-col>
              </v-row>
              <v-divider />

              <v-row class="py-1">
                <v-col cols="6" class="text-caption text--secondary">Nama Petugas</v-col>
                <v-col cols="6" class="text-caption text--secondary text-right"> SUHERNAH </v-col>
              </v-row>
              <v-divider />

              <v-row class="py-1">
                <v-col cols="6" class="text-caption text--secondary">Keterangan</v-col>
                <v-col cols="6" class="text-caption text--secondary text-right"> Tidak ada orang </v-col>
              </v-row>
            </v-card>
          </v-card>
          <v-card v-else class="pa-5 rounded-lg text-center">
            <v-card-title class="text-h5 font-weight-bold"> Data tidak ditemukan </v-card-title>
            <v-card-text> Mohon cek kembali atau coba beberapa saat lagi. </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { ChartRiwayatService } from "@/services/chartRiwayat";

export default {
  data() {
    return {
      isLoading: true,
      detail: {
        image: "",
      },
    };
  },
  watch: {
    "$route.params.id": function () {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      const id = this.$route.params.id;
      try {
        const response = await ChartRiwayatService.getById(id);
        this.detail = response || { image: "" }; // Pastikan tidak null
      } catch (error) {
        console.error("Error fetching data:", error);
        this.detail = { image: "" };
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
