import axios from "axios";

export const ChartRiwayatService = {
  async getAll() {
    try {
      const response = await axios.get(
        "https://fakestoreapi.com/products?limit=5"
      );
      return response.data;
    } catch (error) {
      console.error("Gagal mengambil data chartRiwayat:", error);
      return [];
    }
  },
  async getById(id) {
    try {
      const response = await axios.get(
        `https://fakestoreapi.com/products/${id}`
      );
      return response.data;
    } catch (error) {
      console.error("Gagal mengambil data detail:", error);
      return null;
    }
  },
};

export default ChartRiwayatService;
